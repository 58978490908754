import bell from "icons/bell/index"
import box from "icons/box/index"
import building from "icons/building/index"
import car from "icons/car/index"
import eye from "icons/eye/index"
import forest from "icons/forest/index"
import lightning from "icons/lightning/index"
import location from "icons/location-pin/index"
import man from "icons/man/index"

export default {
  bell,
  box,
  building,
  car,
  eye,
  forest,
  lightning,
  location,
  man,
}
