const ICON_NAME_MAP = {
  bell: "bell",
  inside: "box",
  office: "building",
  car: "car",
  eye: "eye",
  outside: "forest",
  lightning: "lightning",
  map: "map",
  location: "location",
  man: "man",
}

export default ICON_NAME_MAP
