import React from "react"

import Card from "react-bootstrap/Card"
import Image from "react-bootstrap/image"

import ICON_MAP from "icons/icon-map"

//type can be for a card or default image (not on a card)
const Icon = ({
  type = "default",
  shouldAnimate = false,
  gifStyles = {},
  imgStyles = {},
  styleClassNames = {},
  name,
}) => {
  let Component
  switch (type) {
    case "card":
      Component = Card.Img
      break
    default:
      Component = Image
      break
  }

  const icon = ICON_MAP[name]

  return shouldAnimate ? (
    <>
      <Card.Img
        className={styleClassNames.img}
        style={imgStyles}
        src={icon.img}
      />
      <Card.Img
        className={styleClassNames.gif}
        style={gifStyles}
        src={icon.gif}
      />
    </>
  ) : (
    <Component
      clasNames={styleClassNames.img}
      style={imgStyles}
      src={icon.img}
    />
  )
}

export default Icon
