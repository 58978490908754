import React from "react"

import Carousel from "react-bootstrap/Carousel"
import Card from "react-bootstrap/Card"

import Price from "components/unit/price"
import Icon from "components/icon/icon"

import unitCardStyles from "./card.module.less"
import ICON_NAME_MAP from "icons/index"
// import LOCK_LOGO from "imgs/logo/lock.png"

import Img from "gatsby-image"
import uniqid from "uniqid"

import ICON_MAP from "icons/icon-map"
const emptyStatePhoto = ICON_MAP.box.emptyState

const CardCarousel = ({ unit, handleShowModal }) => {
  const handlePropagation = e => e.stopPropagation()
  return (
    <Carousel
      indicators={false}
      interval={null}
      onClick={handlePropagation}
      controls={unit.images.length > 1}
    >
      {unit.images.map(({ node, altText }) => (
        <Carousel.Item onClick={() => handleShowModal(unit)} key={uniqid()}>
          <Img
            fluid={node.childImageSharp.fluid}
            alt={altText}
            className="d-block w-100"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

const iconClassNames = {
  img: unitCardStyles.iconImg,
  gif: unitCardStyles.iconGif,
}

const UnitCard = ({ unit, handleShowModal = () => {} }) => {
  const { price, images, description, title, isOutside, isOffice } = unit

  const iconName = isOutside
    ? ICON_NAME_MAP.outside
    : isOffice
    ? ICON_NAME_MAP.office
    : ICON_NAME_MAP.inside

  return (
    <Card
      className={unitCardStyles.unitCard}
      onClick={() => handleShowModal(unit)}
    >
      {images.length ? (
        <Card.Img
          as={() => (
            <CardCarousel unit={unit} handleShowModal={handleShowModal} />
          )}
        />
      ) : (
        <div className={unitCardStyles.emptyState}>
          <Card.Img
            src={emptyStatePhoto}
            className={unitCardStyles.emptyStateImg}
          />
          <p>No images available</p>
        </div>
      )}
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className={unitCardStyles.info}>
          <div>
            {price && <Price price={price} />}
            <Card.Text>{description}</Card.Text>
          </div>
          <div className={unitCardStyles.icon}>
            <Icon
              type="card"
              shouldAnimate
              styleClassNames={iconClassNames}
              name={iconName}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default UnitCard
