import { useStaticQuery, graphql } from "gatsby"

export const useAllUnitsQuery = () => {
  const data = useStaticQuery(graphql`
    query GetUnitInfo {
      allFile(filter: { sourceInstanceName: { eq: "units" } }) {
        edges {
          node {
            relativeDirectory
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
      allUnitsJson {
        edges {
          node {
            id
            title
            price
            images {
              altText
              name
            }
            holds
            goodFor
            isOutside
            isOffice
          }
        }
      }
    }
  `)

  return data
}
