import React from "react"
import unitCardStyles from "./card/card.module.less"

import Card from "react-bootstrap/Card"

const Price = ({ price }) => (
  <div>
    <Card.Text className={unitCardStyles.price}>{`$${price}`}</Card.Text>
    <Card.Text className="text-muted">per month</Card.Text>
  </div>
)

export default Price
