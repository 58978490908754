import React from "react"

import styles from "components/text/text.module.less"

const Text = ({ size, weight, style, children }) => {
  return (
    <div
      className={styles.text}
      data-size={size}
      data-weight={weight}
      style={style}
    >
      {children}
    </div>
  )
}

export default Text
